import { InMemoryCacheConfig, makeVar } from "@apollo/client";

type NewsType = "overview" | "feed";

export interface Layout {
    sidebar: boolean;
    mobileSidebar: boolean;
    notificationCenter: boolean;
    newsFeed: boolean;
    newsType: NewsType;
}

export const STORAGE_SIDEBAR = "fox.sidebar";

export const sidebarVar = makeVar<boolean>(true);
export const mobileSidebarVar = makeVar<boolean>(false);
export const notificationCenterVar = makeVar<boolean>(false);
export const newsFeedVar = makeVar<boolean>(true);
export const newsTypeVar = makeVar<NewsType>("overview");

export const toggleMobileSidebar = () => mobileSidebarVar(!mobileSidebarVar());
export const toggleNewsFeed = () => newsFeedVar(!newsFeedVar());
export const toggleNotificationCenter = () => notificationCenterVar(!notificationCenterVar());

export const toggleSidebar = () => {
    localStorage.setItem(STORAGE_SIDEBAR, JSON.stringify(!sidebarVar()));
    sidebarVar(!sidebarVar());
};

export const readStorageForSidebar = () => {
    const json = localStorage.getItem(STORAGE_SIDEBAR);

    if (json) {
        const sidebar = JSON.parse(json);

        if (sidebar === true || sidebar === false) {
            sidebarVar(sidebar);
        }
    }
};

export const options: InMemoryCacheConfig = {
    typePolicies: {
        Query: {
            fields: {
                sidebar: {
                    read() {
                        return sidebarVar();
                    },
                },
                mobileSidebar: {
                    read() {
                        return mobileSidebarVar();
                    },
                },
                notificationCenter: {
                    read() {
                        return notificationCenterVar();
                    },
                },
                newsFeed: {
                    read() {
                        return newsFeedVar();
                    },
                },
                newsType: {
                    read() {
                        return newsTypeVar();
                    },
                },
            },
        },
    },
};
